import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import classes from './Home.module.css';
import RatingStars from '../components/RatingStars';
import video from '../assets/vid1.mp4';
import value from '../assets/vector/value.png';
import safety from '../assets/vector/security.png';
import chat from '../assets/vector/chat.png';
import sell from '../assets/vector/sell2.png';

const API_URL = `${window.location.protocol}//db.kenjap.co.ke`;

function Home() {
	const [makes, setMakes] = useState([]);
	const [models, setModels] = useState([]);
	const [selectedMake, setSelectedMake] = useState('');
	const [selectedModel, setSelectedModel] = useState('');

	const [carData, setCarData] = useState([]);
	const [carDataFeat, setCarDataFeat] = useState([]);
	const [categories, setCategories] = useState([]);
	const [featCategory, setFeatCategory] = useState([]);
	const [selectedCategory, setSelectedCategory] = useState('');
	const [selectedFeatCategory, setSelectedFeatCategory] = useState('');

	const [reviews, setReviews] = useState([]);
	const [brands, setBrands] = useState([]);

	const [newUsedValues, setNewUsedValues] = useState([]);
	const [selectedNewUsed, setSelectedNewUsed] = useState('New');

	const navigate = useNavigate();

	useEffect(() => {
		const fetchData = async () => {
			try {
				const carResponse = await axios.get(`${API_URL}/api/vehicles`);
				setCarData(carResponse.data);
				setMakes([...new Set(carResponse.data.map((car) => car.make))].sort());

				setCarDataFeat(carResponse.data);

				const newUsedResponse = await axios.get(
					`${API_URL}/api/vehicles/newUsed`
				);
				setNewUsedValues(newUsedResponse.data);
				if (newUsedResponse.data.length > 0) {
					setSelectedNewUsed(newUsedResponse.data[0]);
				}

				const categoryResponse = await axios.get(`${API_URL}/api/categories`);
				const categoriesData = categoryResponse.data;

				if (categoriesData.includes('SUV')) {
					setSelectedCategory('SUV');
					setSelectedFeatCategory('SUV');
				} else {
					setSelectedCategory(categoriesData[0]);
					setSelectedFeatCategory(categoriesData[0]);
				}

				setCategories(categoriesData);
				setFeatCategory(categoriesData);

				const reviewResponse = await axios.get(`${API_URL}/api/reviews`);
				setReviews(reviewResponse.data);

				const brandResponse = await axios.get(`${API_URL}/api/brands`);
				setBrands(brandResponse.data);
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		};

		fetchData();
	}, []);

	useEffect(() => {
		if (selectedMake) {
			const fetchModels = async () => {
				try {
					const response = await axios.get(
						`${API_URL}/api/vehicles/models/${selectedMake}`
					);
					setModels(response.data.sort());
				} catch (error) {
					console.error('Error fetching models:', error);
				}
			};
			fetchModels();
		} else {
			setModels([]);
		}
	}, [selectedMake]);

	const handleMakeChange = (event) => {
		setSelectedMake(event.target.value);
		setSelectedModel('');
	};

	const handleModelChange = (event) => {
		setSelectedModel(event.target.value);
	};

	const handleCategoryChange = (category) => {
		setSelectedCategory(category);
	};

	const handleFeatCategoryChange = (category) => {
		setSelectedFeatCategory(category);
	};

	const handleNUChange = (newUsed) => {
		setSelectedNewUsed(newUsed);
	};

	const handleNewUsedChange = (event) => {
		setSelectedNewUsed(event.target.value);
	};

	const handleSellYourCar = () => {
		navigate('/sellyourcar');
	};

	const handleSearch = () => {
		const params = new URLSearchParams();
		if (selectedMake) params.append('make', selectedMake);
		if (selectedModel) params.append('model', selectedModel);
		if (selectedNewUsed) params.append('newUsed', selectedNewUsed);

		navigate(`/search?${params.toString()}`);
	};

	const handleSearchFeat = () => {
		const paramss = new URLSearchParams();
		paramss.append('featured', 'true');

		navigate(`/search?${paramss.toString()}`);
	};

	const formatPrice = (price) => {
		return Number(price).toLocaleString('en-US', { maximumFractionDigits: 0 });
	};

	const prioritizeSUVs = (cars) => {
		return cars.sort((a, b) => {
			if (a.category === 'SUV' && b.category !== 'SUV') return -1;
			if (a.category !== 'SUV' && b.category === 'SUV') return 1;
			return 0;
		});
	};

	const prioritizeCategories = (categories) => {
		const prioritized = categories.filter((cat) => cat === 'SUV');
		const others = categories.filter((cat) => cat !== 'SUV').sort();
		return [...prioritized, ...others];
	};

	const prioritizeCategory = (categories) => {
		const prioritized = categories.filter((cat) => cat === 'SUV');
		const others = categories
			.filter(
				(cat) =>
					cat !== 'SUV' &&
					carDataFeat.some((car) => car.category === cat && car.featured)
			)
			.sort();
		return [...prioritized, ...others];
	};

	return (
		<div className={classes.home}>
			<div className={classes.wrapper}>
				<div className={classes.hero}>
					<video
						className={classes.videoBg}
						autoPlay
						loop
						muted>
						<source
							src={video}
							type='video/mp4'
						/>
					</video>
					<div className={classes.text}>
						<h1>Find Your Dream Car Today</h1>
						<div className={classes.search}>
							<select
								className={classes.dropdown}
								onChange={handleMakeChange}
								value={selectedMake}>
								<option value=''>Select Make</option>
								{makes.map((make) => (
									<option
										key={make}
										value={make}>
										{make}
									</option>
								))}
							</select>
							<select
								className={classes.dropdown}
								value={selectedModel}
								onChange={handleModelChange}
								disabled={!selectedMake}>
								<option value=''>Select Model</option>
								{models.map((model) => (
									<option
										key={model}
										value={model}>
										{model}
									</option>
								))}
							</select>
							<select
								className={classes.dropdown}
								onChange={handleNewUsedChange}
								value={selectedNewUsed}>
								<option value=''>Select New/Used</option>
								{[
									'New',
									...newUsedValues.filter((value) => value !== 'New').sort(),
								].map((newUsed) => (
									<option
										key={newUsed}
										value={newUsed}>
										{newUsed}
									</option>
								))}
							</select>

							<button
								className={classes.searchButton}
								onClick={handleSearch}>
								<FontAwesomeIcon icon={faSearch} /> Search
							</button>
						</div>
					</div>
				</div>

				<div className={classes.popularInventory}>
					<h2>Vehicles on Offer</h2>
					<div className={classes.categoryButtons}>
						{prioritizeCategory(featCategory).map((category) => (
							<button
								key={category}
								className={`${classes.categoryButton} ${
									selectedFeatCategory === category ? classes.active : ''
								}`}
								onClick={() => handleFeatCategoryChange(category)}>
								{category}
							</button>
						))}
					</div>
					<div className={classes.vehicleList}>
						{prioritizeSUVs(
							carDataFeat.filter(
								(car) => car.category === selectedFeatCategory && car.featured
							)
						).map((car, index) => (
							<div
								className={classes.vehicle}
								key={index}
								onClick={() => {
									navigate(`/car/${car.id}`);
								}}>
								<div className={classes.image}>
									{car.images && car.images.length > 0 && (
										<img
											src={`${API_URL}/images/cars/${car.images[0].imageName}`}
											alt={`${car.make} ${car.model}`}
										/>
									)}
									<p className={car.featured ? classes.offer : classes.hidden}>
										On Offer!!
									</p>
								</div>
								<div className={classes.desc}>
									<h3>{`${car.make} ${car.model}`}</h3>
									<div className={classes.descFlex}>
										<h4>{formatPrice(car.price)}</h4>
										<p
											onClick={() => {
												navigate(`/car/${car.id}`);
											}}>
											Shop now
										</p>
									</div>
								</div>
							</div>
						))}
					</div>
					<div className={classes.shomo}>
						<button onClick={handleSearchFeat}>Show More</button>
					</div>
				</div>

				<div className={classes.popularInventory}>
					<h2>Popular Categories</h2>
					<div className={classes.categoryButtons}>
						{prioritizeCategories(categories).map((category) => (
							<button
								key={category}
								className={`${classes.categoryButton} ${
									selectedCategory === category ? classes.active : ''
								}`}
								onClick={() => handleCategoryChange(category)}>
								{category}
							</button>
						))}
					</div>
					<div className={classes.vehicleList}>
						{prioritizeSUVs(
							carData.filter((car) => car.category === selectedCategory)
						).map((car, index) => (
							<div
								className={classes.vehicle}
								key={index}
								onClick={() => {
									navigate(`/car/${car.id}`);
								}}>
								<div className={classes.image}>
									{car.images && car.images.length > 0 && (
										<img
											src={`${API_URL}/images/cars/${car.images[0].imageName}`}
											alt={`${car.make} ${car.model}`}
										/>
									)}
									<p className={car.featured ? classes.offer : classes.hidden}>
										On Offer!!
									</p>
								</div>
								<div className={classes.desc}>
									<h3>{`${car.make} ${car.model}`}</h3>
									<div className={classes.descFlex}>
										<h4>{formatPrice(car.price)}</h4>
										<p
											onClick={() => {
												navigate(`/car/${car.id}`);
											}}>
											Shop now
										</p>
									</div>
								</div>
							</div>
						))}
					</div>
					<div className={classes.shomo}>
						<Link
							to={'/search'}
							className={classes.btn}>
							Show More
						</Link>
					</div>
				</div>

				<div className={classes.featuredInventory}>
					<h2>Featured Vehicles</h2>
					<div className={classes.newUsedFilters}>
						{newUsedValues.map((newUsed, index) => (
							<button
								key={index}
								className={`${classes.filterButton} ${
									selectedNewUsed === newUsed ? classes.active : ''
								}`}
								onClick={() => handleNUChange(newUsed)}>
								{newUsed}
							</button>
						))}
					</div>
					<div className={classes.vehicleList}>
						{carData
							.filter((car) => car.newUsed === selectedNewUsed)
							.map((car, index) => (
								<div
									className={classes.vehicle}
									key={index}
									onClick={() => {
										navigate(`/car/${car.id}`);
									}}>
									<div className={classes.image}>
										{car.images && car.images.length > 0 && (
											<img
												src={`${API_URL}/images/cars/${car.images[0].imageName}`}
												alt={`${car.make} ${car.model}`}
											/>
										)}
										<p
											className={car.featured ? classes.offer : classes.hidden}>
											On Offer!!
										</p>
									</div>
									<div className={classes.desc}>
										<h3>{`${car.make} ${car.model}`}</h3>
										<div className={classes.descFlex}>
											<h4>{formatPrice(car.price)}</h4>
											<p
												onClick={() => {
													navigate(`/car/${car.id}`);
												}}>
												Shop now
											</p>
										</div>
									</div>
								</div>
							))}
					</div>
					<div className={classes.shomo}>
						<Link
							to={'/search'}
							className={classes.btn}>
							Show More
						</Link>
					</div>
				</div>

				<div className={classes.sellcar}>
					<div className={classes.txt}>
						<h2>Sell Your Car</h2>
						<p>
							Thinking of selling your car? We've got you covered! Get started
							now by clicking the button below.
						</p>
						<button onClick={handleSellYourCar}>Get Started</button>
					</div>
					<div className={classes.image}>
						<img
							src={sell}
							alt='Sell your car'
						/>
					</div>
				</div>

				{/* why us */}
				<div className={classes.whyKenjap}>
					<h2>Why choose us?</h2>
					<div className={classes.reasons}>
						<div className={classes.reason}>
							<div className={classes.image}>
								<img
									src={value}
									alt='Why Kenjap'
								/>
							</div>

							<div className={classes.txt}>
								<h4>01.</h4>
								<h3>Value for Money</h3>
								<p>
									At Kenjap Motors, we are committed to providing our customers
									with the best value for their money. Our vehicles are priced
									competitively, ensuring you receive top-notch quality without
									breaking the bank. Whether you're looking for an economical
									car or a premium model, our selection is curated to offer
									excellent performance and durability at great prices. With
									Kenjap Motors, you can be confident that you're making a smart
									investment, getting more for every dollar spent.
								</p>
							</div>
						</div>
						<div className={classes.reason}>
							<div className={classes.image}>
								<img
									src={safety}
									alt='Why Kenjap'
								/>
							</div>

							<div className={classes.txt}>
								<h4>02.</h4>
								<h3>Safety</h3>
								<p>
									Safety is our top priority at Kenjap Motors. We only sell
									vehicles that meet stringent safety standards and are equipped
									with advanced safety features. Our inventory includes cars
									with the latest safety technologies such as anti-lock brakes,
									airbags, stability control, and more. We ensure that every
									vehicle undergoes thorough safety checks and maintenance to
									provide you with peace of mind on the road. Drive with
									confidence knowing that your safety is assured with Kenjap
									Motors.
								</p>
							</div>
						</div>
						<div className={classes.reason}>
							<div className={classes.image}>
								<img
									src={chat}
									alt='Why Kenjap'
								/>
							</div>

							<div className={classes.txt}>
								<h4>03.</h4>
								<h3>Customer Feedback</h3>
								<p>
									Customer satisfaction is at the heart of what we do. We take
									pride in the positive feedback and testimonials from our
									satisfied customers. At Kenjap Motors, we listen to our
									customers' needs and continuously strive to exceed their
									expectations. Our dedicated team is always available to assist
									you, ensuring a smooth and enjoyable car-buying experience.
									Join the Kenjap Motors family and see why so many customers
									recommend us to their friends and family.
								</p>
							</div>
						</div>
					</div>
				</div>

				{/* Client reviews */}
				<div className={classes.reviews}>
					<h2>Client Reviews</h2>
					<div className={classes.reviewList}>
						{reviews.map((review, index) => (
							<div
								className={classes.review}
								key={index}>
								<h3>{review.user}</h3>
								<div className={classes.rating}>
									<p>Rating: </p>
									<div className={classes.rate}>
										<RatingStars rating={review.rating} />
									</div>
								</div>
								<h4>{review.car}</h4>
								<p>"{review.comment}"</p>
							</div>
						))}
					</div>
				</div>

				{/* Brand Partners */}
				<div className={classes.brands}>
					<h2>Brands We Sell</h2>
					<div className={classes.brandListWrapper}>
						<div
							className={classes.brandList}
							id='brandList'>
							{brands.concat(brands).map((brand, index) => {
								const imageUrl = `${API_URL}/images/brands/${brand.logo}`;
								return (
									<div
										className={classes.brand}
										key={index}>
										<img
											src={imageUrl}
											alt={brand.name}
										/>
									</div>
								);
							})}
						</div>
					</div>
				</div>

				{/* end */}
			</div>
		</div>
	);
}

export default Home;
